import { ReturnQuestionProjection } from "../../../projection/returnQuestion/returnQuestion";
import { ReturnQuestionType } from "../../../projection/returnQuestion/returnQuestion.constants";

const returnQuestions: ReturnQuestionProjection[] = [
  {
    id: "efe07b97-f6fb-4b6a-ba92-b68519320b2c",
    translationKey: "main_title",
    type: ReturnQuestionType.HOST_DEFAULT,
    children: [
      {
        id: "ed396b68-1436-48ab-842f-fbfea996fad4",
        translationKey: "size",
        type: ReturnQuestionType.HOST_SELECT,
        metadata: {
          placeholder: "size_placeholder",
        },
        children: [
          {
            id: "0ad1dba8-b02c-4121-a1e3-981f1c30800d",
            translationKey: "size_title",
            type: ReturnQuestionType.HOST_STACK,
            children: [
              {
                id: "9251dc2c-d76a-484d-9299-346929af932f",
                translationKey: "size_large",
                type: ReturnQuestionType.OPTION,
                children: [
                  {
                    id: "542c4d24-e1da-484f-8c3a-7d89ee135adc",
                    type: ReturnQuestionType.HOST_STACK,
                    children: [
                      {
                        id: "68c0bb98-b00a-4b86-af43-528fe903cb69",
                        translationKey: "size_overall",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "85e84a77-461c-41e6-8544-95b5294d0b80",
                        translationKey: "size_trousers_too_long",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "29441d25-8d9f-471e-a13e-a03f61c88091",
                        translationKey: "size_fit_cut_does_not_fit",
                        type: ReturnQuestionType.OPTION,
                      },
                    ],
                  },
                ],
              },
              {
                id: "a081c3e9-1904-4a60-8899-b72c1a3a0291",
                translationKey: "size_correct",
                type: ReturnQuestionType.OPTION,
              },
              {
                id: "624cce3d-bd86-463a-b16b-c862ad73d5f3",
                translationKey: "size_small",
                type: ReturnQuestionType.OPTION,
                children: [
                  {
                    id: "58e5ae9a-bc0d-4d27-b267-854a51c2a79a",
                    type: ReturnQuestionType.HOST_STACK,
                    children: [
                      {
                        id: "6a3d11fc-570c-4085-a850-1967228cdd4c",
                        translationKey: "size_overall",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "c27e1936-deea-4dbe-a8f1-fe4fb8564deb",
                        translationKey: "size_trousers_too_short",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "e77232ae-eeec-4722-b2d3-f5c0d34fd0ca",
                        translationKey: "size_fit_cut_does_not_fit",
                        type: ReturnQuestionType.OPTION,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "6a6d3878-5561-412c-96f3-7ed79ee587bb",
        translationKey: "style",
        type: ReturnQuestionType.HOST_SELECT,
        metadata: {
          placeholder: "style_placeholder",
        },
        children: [
          {
            id: "fd7ff4a0-78ed-4c0d-aa4d-64962739c322",
            translationKey: "style_title",
            type: ReturnQuestionType.HOST_STACK,
            children: [
              {
                id: "56631448-d33d-49fb-b3f8-4486b68b60c3",
                translationKey: "style_i_love_it_but",
                type: ReturnQuestionType.OPTION,
                children: [
                  {
                    id: "90329d91-bd5d-4439-bb0a-b338caaeff2b",
                    type: ReturnQuestionType.HOST_STACK,
                    children: [
                      {
                        id: "a3d937b6-7499-4a3c-86bf-5c0af9ab2e23",
                        translationKey: "style_i_already_have_something_similar",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "7970edd3-fb5c-42d3-afe3-ce31bcbb856c",
                        translationKey: "style_very_basic_simple",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "bab3d4f9-0227-4550-a53f-3c125d68429c",
                        translationKey: "style_i_dont_need_it_at_the_moment",
                        type: ReturnQuestionType.OPTION,
                      },
                    ],
                  },
                ],
              },
              {
                id: "5287d1df-a28a-42cd-9138-01f82aeea4c2",
                translationKey: "style_i_like_it_but",
                type: ReturnQuestionType.OPTION,
                children: [
                  {
                    id: "35b86395-6b6b-4a39-ab16-05511f58acdd",
                    type: ReturnQuestionType.HOST_STACK,
                    children: [
                      {
                        id: "e1f6ac53-f203-4b32-9ff0-782c028d9e95",
                        translationKey: "style_i_already_have_something_similar",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "12875926-b67a-4ad3-852c-1608c7dcc558",
                        translationKey: "style_very_basic_simple",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "a5eb96bf-73d4-4df5-8ad8-097410b24526",
                        translationKey: "style_i_dont_need_it_at_the_moment",
                        type: ReturnQuestionType.OPTION,
                      },
                    ],
                  },
                ],
              },
              {
                id: "757107a9-8e2d-4194-a7fe-0d55cde49c11",
                translationKey: "style_not_bad_but",
                type: ReturnQuestionType.OPTION,
                children: [
                  {
                    id: "c6f75ee3-a445-4103-a682-f0c3a742108d",
                    type: ReturnQuestionType.HOST_STACK,
                    children: [
                      {
                        id: "4f46d4b1-b103-43b2-b669-be721ab46e03",
                        translationKey: "style_i_dont_like_the_color",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "48412165-5e05-4806-8e21-ea59c41d0479",
                        translationKey: "style_very_basic_simple",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "51262c01-eaa3-4d3a-aeb9-95dc8b0dd687",
                        translationKey: "style_i_dont_like_the_fabric",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "9dea1c2b-4f8c-4192-a547-999df55e77e4",
                        translationKey: "style_i_dont_like_the_print",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "b1277bf3-4bbb-487a-8e58-88efecc61c3f",
                        translationKey: "style_not_my_style_in_general",
                        type: ReturnQuestionType.OPTION,
                      },
                    ],
                  },
                ],
              },
              {
                id: "8a9cd6f8-db4b-4904-8a48-3d9bbd6f7a69",
                translationKey: "style_i_dont_like",
                type: ReturnQuestionType.OPTION,
                children: [
                  {
                    id: "f604240d-c093-4a9e-ab85-4adc0744066a",
                    type: ReturnQuestionType.HOST_STACK,
                    children: [
                      {
                        id: "42dbbb93-7808-4ac3-8431-582f812cb314",
                        translationKey: "style_i_dont_like_the_color",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "af1ade81-7b22-4f7e-a505-2ae275a6440f",
                        translationKey: "style_very_basic_simple",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "26d073b4-2588-4391-9b4c-e8e4a8eba2a8",
                        translationKey: "style_i_dont_like_the_fabric",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "ee50ab07-b7a6-415e-a04a-c9f350396185",
                        translationKey: "style_i_dont_like_the_print",
                        type: ReturnQuestionType.OPTION,
                      },
                      {
                        id: "ce3227e4-851e-47b7-98c1-c63a402e8477",
                        translationKey: "style_not_my_style_in_general",
                        type: ReturnQuestionType.OPTION,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: "a1acdb2d-62c8-467f-b7cc-dc24d967290c",
        translationKey: "quality",
        type: ReturnQuestionType.HOST_SELECT,
        metadata: {
          placeholder: "quality_placeholder",
        },
        children: [
          {
            id: "405de847-188f-46c1-b66d-941c22a6b7a9",
            translationKey: "quality_title",
            type: ReturnQuestionType.HOST_STACK,
            children: [
              {
                id: "cce7851c-92c7-461b-8dcb-903c5de59ed1",
                translationKey: "quality_good",
                type: ReturnQuestionType.OPTION,
              },
              {
                id: "4bf8f27d-669b-4d36-8d39-b5599c671816",
                translationKey: "quality_regular",
                type: ReturnQuestionType.OPTION,
              },
              {
                id: "48bfe766-92c0-4ff5-a793-e107b60786ff",
                translationKey: "quality_bad",
                type: ReturnQuestionType.OPTION,
              },
            ],
          },
        ],
      },
      {
        id: "f25ccdcf-b648-479e-b4a1-86129e3e8c0b",
        translationKey: "price",
        type: ReturnQuestionType.HOST_SELECT,
        metadata: {
          placeholder: "price_placeholder",
        },
        children: [
          {
            id: "b0e0463c-787f-4d6c-adf3-5151ab6fc959",
            translationKey: "price_title",
            type: ReturnQuestionType.HOST_STACK,
            children: [
              {
                id: "9c59e396-4135-4255-9437-22555837d686",
                translationKey: "price_expensive",
                type: ReturnQuestionType.OPTION,
              },
              {
                id: "10db17f8-0982-49ee-9aeb-f752d953654c",
                translationKey: "price_cheap",
                type: ReturnQuestionType.OPTION,
              },
              {
                id: "acf64bb3-b91b-48e7-bba1-0541620ed1c5",
                translationKey: "price_fair",
                type: ReturnQuestionType.OPTION,
              },
            ],
          },
        ],
      },
      {
        id: "b7ad1665-5e54-4ee4-b455-e7189f33083c",
        translationKey: "others",
        type: ReturnQuestionType.HOST_SELECT,
        metadata: {
          placeholder: "others_placeholder",
        },
        children: [
          {
            id: "32cd0ef9-78d7-4605-a279-556749b36d4a",
            translationKey: "others_title",
            type: ReturnQuestionType.HOST_STACK,
            children: [
              {
                id: "95c4fd13-9ca3-45a6-8795-23415bbd0a5e",
                translationKey: "others_i_dont_like_the_brand",
                type: ReturnQuestionType.OPTION,
              },
              {
                id: "f25c63bd-42d3-4242-9b81-927025aa9d67",
                translationKey: "others_different_from_preview",
                type: ReturnQuestionType.OPTION,
              },
              {
                id: "0e0b16c1-a721-4a14-aea6-e0cf5c262222",
                translationKey: "others_delivery_delay",
                type: ReturnQuestionType.OPTION,
              },
              {
                id: "11699ae8-325d-429c-9c05-ff2d6cc45d54",
                translationKey: "others_item_is_defective",
                type: ReturnQuestionType.OPTION,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "ce3e8d57-5eea-4dd3-bef4-2e811a143612",
    translationKey: "comment_title",
    type: ReturnQuestionType.HOST_TEXTAREA,
    children: [
      {
        id: "1123a37d-bc00-43a4-9d28-cee1dfaf356c",
        translationKey: "comment_textarea",
        type: ReturnQuestionType.TEXTAREA,
        metadata: {
          placeholder: "comment_textarea_placeholder",
        },
      },
    ],
  },
];

export { returnQuestions };
