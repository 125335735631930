import {
  CheckoutQuestionProjection,
  CheckoutQuestionType,
} from "../../../projection/checkoutQuestion/checkoutQuestion";

const checkoutQuestions: CheckoutQuestionProjection[] = [
  {
    id: "efe07b97-f6fb-4b6a-ba92-b68519320b2c",
    name: "checkout_question.selection.question",
    placeholder: "checkout_question.selection.placeholder",
    type: CheckoutQuestionType.HOST_SELECT,
    showCondition: [],
    children: [
      {
        id: "68c0bb98-b00a-4b86-af43-528fe903cb69",
        name: "checkout_question.icon.bad",
        placeholder: "",
        type: CheckoutQuestionType.ICON,
        showCondition: [],
      },
      {
        id: "85e84a77-461c-41e6-8544-95b5294d0b80",
        name: "checkout_question.icon.regular",
        placeholder: "",
        type: CheckoutQuestionType.ICON,
        showCondition: [],
      },
      {
        id: "29441d25-8d9f-471e-a13e-a03f61c88091",
        name: "checkout_question.icon.good",
        placeholder: "",
        type: CheckoutQuestionType.ICON,
        showCondition: [],
      },
    ],
  },
  {
    id: "6a6d3878-5561-412c-96f3-7ed79ee587bb",
    name: "checkout_question.experience.question",
    placeholder: "checkout_question.experience.placeholder",
    type: CheckoutQuestionType.HOST_SELECT,
    showCondition: [],
    children: [
      {
        id: "a3d937b6-7499-4a3c-86bf-5c0af9ab2e23",
        name: "checkout_question.icon.bad",
        placeholder: "",
        type: CheckoutQuestionType.ICON,
        showCondition: [],
      },
      {
        id: "7970edd3-fb5c-42d3-afe3-ce31bcbb856c",
        name: "checkout_question.icon.regular",
        placeholder: "",
        type: CheckoutQuestionType.ICON,
        showCondition: [],
      },
      {
        id: "bab3d4f9-0227-4550-a53f-3c125d68429c",
        name: "checkout_question.icon.good",
        placeholder: "",
        type: CheckoutQuestionType.ICON,
        showCondition: [],
      },
    ],
  },
  {
    id: "a1acdb2d-62c8-467f-b7cc-dc24d967290c",
    name: "checkout_question.personalshopper.question",
    placeholder: "checkout_question.personalshopper.placeholder",
    type: CheckoutQuestionType.HOST_SELECT,
    showCondition: ["68c0bb98-b00a-4b86-af43-528fe903cb69"],
    children: [
      {
        id: "cce7851c-92c7-461b-8dcb-903c5de59ed1",
        name: "checkout_question.personalshopper.yes",
        placeholder: "",
        type: CheckoutQuestionType.BUTTON,
        showCondition: [],
      },
      {
        id: "4bf8f27d-669b-4d36-8d39-b5599c671816",
        name: "checkout_question.personalshopper.no",
        placeholder: "",
        type: CheckoutQuestionType.BUTTON,
        showCondition: [],
      },
    ],
  },
  {
    id: "ce3e8d57-5eea-4dd3-bef4-2e811a143612",
    name: "checkout_question.comment.question",
    placeholder: "",
    type: CheckoutQuestionType.HOST_TEXTAREA,
    showCondition: [],
    children: [
      {
        id: "1123a37d-bc00-43a4-9d28-cee1dfaf356c",
        name: "checkout_question.comment.question",
        placeholder: "checkout_question.comment.placeholder",
        type: CheckoutQuestionType.TEXTAREA,
        showCondition: [],
      },
    ],
  },
];

export { checkoutQuestions };
