import { CheckoutFeedbackProjection } from "../../../projection/checkoutFeedback/checkoutFeedback";

interface CheckoutFeedbackDataSourceFunctionArgs {
  readonly data: CheckoutFeedbackProjection;
}

interface CheckoutFeedbackDataSource {
  readonly getCheckoutFeedback: (id: string) => CheckoutFeedbackProjection | undefined;
  readonly saveCheckoutFeedback: (checkoutFeedback: CheckoutFeedbackProjection) => void;
}

interface CheckoutFeedbackDataSourceFunction {
  (args: CheckoutFeedbackDataSourceFunctionArgs): CheckoutFeedbackDataSource;
}

const checkoutFeedbackDataSource: CheckoutFeedbackDataSourceFunction = ({ data: initialData }) => {
  let data: CheckoutFeedbackProjection = initialData;

  const getCheckoutFeedback = () => data;
  const saveCheckoutFeedback = (checkoutFeedback: CheckoutFeedbackProjection) => {
    data = checkoutFeedback;
  };

  return {
    getCheckoutFeedback,
    saveCheckoutFeedback,
  };
};

export type { CheckoutFeedbackDataSource };
export { checkoutFeedbackDataSource };
