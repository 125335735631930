import invariant from "tiny-invariant";
import { v4 as uuid } from "uuid";
import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { CheckoutBooking } from "../../../domain/checkoutBooking/model/checkoutBooking";
import {
  CheckoutBookingsGetFunction,
  CheckoutBookingsSaveFunction,
} from "../../../domain/checkoutBooking/model/checkoutBookings";
import {
  ViewBookedProductsVariantsForCheckoutItem,
  viewBookedProductsVariantsForCheckoutItem,
  ViewBookedProductsVariantsForCheckoutItemResult,
} from "../../../projection/bookedProductsVariants/viewBookedProductVariantsForCheckoutItem";
import { CheckoutBookingProjection } from "../../../projection/checkoutBooking/checkoutBooking";
import { CheckoutBookingDataSource } from "./checkoutBookingDataSource";

const toCheckoutBookingDomain: (
  checkoutBooking: ViewBookedProductsVariantsForCheckoutItemResult,
) => CheckoutBooking | never = (checkoutBooking) => {
  invariant(checkoutBooking, "No checkoutBooking found!");

  return {
    aggregateId: uuid(),
    checkoutItemIds: checkoutBooking.productVariants.map((productVariant) => productVariant.id),
    isExpired: false,
    domainEvents: [],
  };
};

const toCheckoutBookingProjection: (checkoutBooking: CheckoutBooking) => CheckoutBookingProjection = (
  checkoutBooking,
) => ({
  id: checkoutBooking.aggregateId,
  checkoutItemIds: checkoutBooking.checkoutItemIds,
  isExpired: false,
});

const getCheckoutBooking: CheckoutBookingsGetFunction<RepositoryGetFunctionArgs> =
  ({ queryBus }) =>
  async (aggregateId) =>
    toCheckoutBookingDomain(
      await queryBus<ViewBookedProductsVariantsForCheckoutItem, ViewBookedProductsVariantsForCheckoutItemResult>(
        viewBookedProductsVariantsForCheckoutItem({ checkoutItemId: aggregateId }),
      ),
    );

interface DataSourceCheckoutItemsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly dataSource: CheckoutBookingDataSource;
}

interface DataSourceCheckoutItemsSaveFunction
  extends CheckoutBookingsSaveFunction<DataSourceCheckoutItemsSaveFunctionArgs> {}

const saveCheckoutBooking: DataSourceCheckoutItemsSaveFunction =
  ({ dataSource }) =>
  async (aggregateRoot) => {
    dataSource.saveCheckoutBooking(toCheckoutBookingProjection(aggregateRoot));
  };

export { getCheckoutBooking, saveCheckoutBooking, toCheckoutBookingProjection };
