import { BookedProductsVariantsProjection } from "../../../projection/bookedProductsVariants/bookedProductsVariants";

const bookedProductsVariants: BookedProductsVariantsProjection = {
  productVariants: [
    {
      id: "383fab95-56eb-4639-bb18-530ab792b4d3",
      size: {
        id: "4f8a26b8-8d2d-4012-b49a-7d2820095598",
        europe: "S",
        lookiero: "S",
        uk: "S",
        it: "S",
        unique: false,
      },
    },
    {
      id: "01939161-b42a-7ee4-9b82-bc61f0b60734",
      size: {
        id: "01938c77-ee0b-7ee0-bd08-9c7131e66a53",
        europe: "M",
        lookiero: "M",
        uk: "M",
        it: "M",
        unique: false,
      },
    },
  ],
};

export { bookedProductsVariants };
