import { PaymentFlowPayloadProjection } from "../../../projection/payment/paymentFlowPayload";

const paymentFlowPayloadForKeptItems: PaymentFlowPayloadProjection = {
  bookingId: "",
  orderId: "3062898",
  comment: "",
  items: [
    { reference: "9P1435C9L" },
    { reference: "9C2017C22M" },
    { reference: "9J1800C22M" },
    { reference: "9M608C15L" },
    { reference: "10S769C48S" },
  ],
};

const paymentFlowPayloadForReturnedItems: PaymentFlowPayloadProjection = {
  bookingId: "",
  orderId: "3071170",
  comment: "",
  items: [
    {
      reference: "9J1800C22M",
      feedback: {
        size: { name: "La talla es...", value: "3" },
        price: { name: "El precio es...", value: "8" },
        quality: { name: "La calidad es...", value: "15" },
        style: { name: "El estilo es...", value: "5" },
        shape: { name: "La forma es...", value: "12" },
        comment: { name: "¿Algo más sobre esta prenda?", value: "Me queda pequeño" },
      },
    },
    {
      reference: "10S769C48S",
      feedback: {
        size: { name: "La talla es...", value: "1" },
        price: { name: "El precio es...", value: "10" },
        quality: { name: "La calidad es...", value: "15" },
        style: { name: "El estilo es...", value: "5" },
        shape: { name: "La forma es...", value: "14" },
        comment: { name: "¿Algo más sobre esta prenda?", value: "Me queda grande y no me gusta demasiado" },
      },
    },
    {
      reference: "9C2017C22M",
      feedback: {
        size: { name: "La talla es...", value: "2" },
        price: { name: "El precio es...", value: "10" },
        quality: { name: "La calidad es...", value: "15" },
        style: { name: "El estilo es...", value: "5" },
        shape: { name: "La forma es...", value: "12" },
        comment: { name: "¿Algo más sobre esta prenda?", value: "Demasiado caro para mi presupuesto" },
      },
    },
    {
      reference: "9M608C15L",
      feedback: {
        style: { name: "El estilo es...", value: "7" },
        shape: { name: "La forma es...", value: "14" },
        quality: { name: "La calidad es...", value: "15" },
        price: { name: "El precio es...", value: "9" },
        size: { name: "La talla es...", value: "2" },
        comment: { name: "¿Algo más sobre esta prenda?", value: "No me gusta nada" },
      },
    },
    {
      reference: "9P1435C9L",
      feedback: {
        quality: { name: "La calidad es...", value: "17" },
        size: { name: "La talla es...", value: "2" },
        price: { name: "El precio es...", value: "10" },
        style: { name: "El estilo es...", value: "5" },
        shape: { name: "La forma es...", value: "12" },
        comment: { name: "¿Algo más sobre esta prenda?", value: "Demasiado caro para la calidad que ofrece" },
      },
    },
  ],
};

const paymentFlowPayloadForReplacedItems: PaymentFlowPayloadProjection = {
  bookingId: "ca2e03e6-1644-4406-a379-0e5c3c321f32",
  orderId: "3071171",
  comment: "",
  items: [
    {
      reference: "9J1800C22M",
      sizeChange: {
        brand: "ONLY",
        color: "blue_dark",
        description: "Onlglamour Sweater knit T. XS C. blue_dark",
        id: "5a95dffc-5031-4c2a-b8bc-f100c4710336",
        reference: "9J1800C22XS",
        size: "XS",
        title: "Onlglamour Sweater knit ",
      },
    },
    {
      reference: "10S769C48S",
      sizeChange: {
        brand: "FRANSA",
        color: "brick",
        description: "Zawov Shirt fransa T. XS C. brick",
        id: "b74df550-a4ab-4fb0-9a34-5767c700dcd0",
        reference: "10S769C48XS",
        size: "XS",
        title: "Zawov Shirt fransa ",
      },
    },
    {
      reference: "9C2017C22M",
      sizeChange: {
        brand: "VERO MODA",
        color: "blue_dark",
        description: "Vmlina Tshirt vback T. XS C. blue_dark",
        id: "349a4aeb-d8ca-4f3d-9efb-5b333da22290",
        reference: "9C2017C22XS",
        size: "XS",
        title: "Vmlina Tshirt vback ",
      },
    },
    {
      reference: "9M608C15L",
      sizeChange: {
        brand: "ESPRIT CASUAL",
        color: "beige",
        description: "Hope Jeans skinny T. S C. beige",
        id: "86aed12f-81e1-4a09-9c68-15dce7a844b7",
        reference: "9M608C15S",
        size: "S",
        title: "Hope Jeans skinny ",
      },
    },
    {
      reference: "9P1435C9L",
      sizeChange: {
        brand: "ICHI",
        color: "orange",
        description: "Kate Pant mazarine T. S C. orange",
        id: "34f311e2-ba8e-4261-bf70-6f25fc0e9ed9",
        reference: "9P1435C9S",
        size: "S",
        title: "Kate Pant mazarine ",
      },
    },
  ],
};

const paymentFlowPayload: PaymentFlowPayloadProjection = {
  bookingId: "7a3c298a-a3ee-45ac-a452-e276c1c98069",
  orderId: "3062898",
  comment: "",
  items: [
    {
      reference: "9P1435C9L",
      feedback: {
        size: {
          name: "La talla es...",
          value: "1",
        },
        price: {
          name: "El precio es...",
          value: "10",
        },
      },
    },
    {
      reference: "9C2017C22M",
    },
    {
      reference: "9J1800C22M",
    },
    {
      reference: "9M608C15L",
    },
    {
      reference: "10S769C48S",
      sizeChange: {
        brand: "FRANSA",
        color: "brick",
        description: "Zawov Shirt fransa T. M C. brick",
        id: "dbabf321-2ca8-4ac0-a727-05e39779e3b3",
        reference: "10S769C48M",
        size: "M",
        title: "Zawov Shirt fransa ",
      },
    },
  ],
};

export {
  paymentFlowPayload,
  paymentFlowPayloadForKeptItems,
  paymentFlowPayloadForReturnedItems,
  paymentFlowPayloadForReplacedItems,
};
