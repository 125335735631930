import invariant from "tiny-invariant";
import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { Checkout } from "../../../domain/checkout/model/checkout";
import { CheckoutsGetFunction, CheckoutsSaveFunction } from "../../../domain/checkout/model/checkouts";
import { CheckoutProjection } from "../../../projection/checkout/checkout";
import {
  viewCheckoutById,
  ViewCheckoutById,
  ViewCheckoutByIdResult,
} from "../../../projection/checkout/viewCheckoutById";
import { CheckoutDataSource } from "./checkoutDataSource";

const toCheckoutDomain: (checkout: ViewCheckoutByIdResult) => Checkout | never = (checkout) => {
  invariant(checkout, "No checkout found!");

  return {
    id: checkout.id,
    status: checkout.status,
    customerId: checkout.customerId,
    boxId: checkout.boxId,
    checkoutBookingId: checkout.checkoutBookingId,
    expiresOn: checkout.expiresOn,
    aggregateId: checkout.id,
    domainEvents: [],
  };
};

const toCheckoutProjection: (checkout: Checkout) => CheckoutProjection = (checkout) => ({
  id: checkout.aggregateId,
  status: checkout.status,
  customerId: checkout.customerId,
  boxId: checkout.boxId,
  checkoutBookingId: checkout.checkoutBookingId,
  expiresOn: checkout.expiresOn,
  items: [],
});

const getCheckout: CheckoutsGetFunction<RepositoryGetFunctionArgs> =
  ({ queryBus }) =>
  async (aggregateId) =>
    toCheckoutDomain(
      await queryBus<ViewCheckoutById, ViewCheckoutByIdResult>(viewCheckoutById({ checkoutId: aggregateId })),
    );

interface DataSourceCheckoutsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly dataSource: CheckoutDataSource;
}

interface DataSourceCheckoutsSaveFunction extends CheckoutsSaveFunction<DataSourceCheckoutsSaveFunctionArgs> {}

const saveCheckout: DataSourceCheckoutsSaveFunction =
  ({ dataSource }) =>
  async (aggregateRoot) =>
    dataSource.saveCheckout(toCheckoutProjection(aggregateRoot));

export { getCheckout, saveCheckout };
