import React, { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { LayoutRectangle, Platform, ScrollView, View } from "react-native";
import { useNavigate } from "react-router-native";
import { Box, Button, Layout as AuroraLayout, Spinner, Text, useDevice } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { QueryStatus } from "@lookiero/messaging-react";
import { Layout as UiLayout, Sticky } from "@lookiero/sty-psp-ui";
import { CheckoutItemStatus } from "../../../../domain/checkoutItem/model/checkoutItem";
import { useViewFirstAvailableCheckoutByCustomerId } from "../../../projection/checkout/react/useViewFirstAvailableCheckoutByCustomerId";
import { useViewPricingByCheckoutId } from "../../../projection/pricing/react/useViewPricingByCheckoutId";
import { TrackingPage } from "../../../tracking/tracking";
import { useTrackPageView } from "../../../tracking/useTrackPageView";
import { useTrackPressBack } from "../../../tracking/useTrackPressBack";
import { useTrackPressContinue } from "../../../tracking/useTrackPressContinue";
import { Body } from "../../components/layouts/body/Body";
import { CheckoutHeader } from "../../components/templates/header/checkoutHeader/CheckoutHeader";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { I18nMessages } from "../../i18n/i18n";
import { Routes } from "../../routing/routes";
import { useBasePath } from "../../routing/useBasePath";
import { ProductVariant } from "../shared/components/productVariant/ProductVariant";
import { Pricing } from "../summary/components/pricing/Pricing";
import { style } from "./Checkout.style";
import { DeliveryBanner } from "./components/deliveryBanner/DeliveryBanner";
import { PaymentInstrument } from "./components/paymentInstrument/PaymentInstrument";

interface CheckoutProps {
  readonly children?: ReactNode;
  readonly layout: UiLayout;
  readonly useRedirect: () => Record<string, string>;
}

const Checkout: FC<CheckoutProps> = ({ children, layout: Layout, useRedirect }) => {
  const {
    customer: { customerId, country, segment },
  } = useStaticInfo();
  const titleText = useI18nMessage({ id: I18nMessages.CHECKOUT_TITLE });
  const submitButtonText = useI18nMessage({ id: I18nMessages.CHECKOUT_PAY_BUTTON });
  const { screen } = useDevice();
  const [pricingHeight, setPricingHeight] = useState(0);
  const handleOnPricingLayout = useCallback(({ height }: LayoutRectangle) => setPricingHeight(height), []);

  const [checkout, checkoutStatus] = useViewFirstAvailableCheckoutByCustomerId({ customerId });
  const [pricing, pricingStatus] = useViewPricingByCheckoutId({ checkoutId: checkout?.id as string });

  useTrackPageView({
    page: TrackingPage.CHECKOUT,
    country,
    segment,
    checkoutId: checkout?.id,
  });

  const navigate = useNavigate();
  const basePath = useBasePath();

  const trackPressContinue = useTrackPressContinue({
    page: TrackingPage.CHECKOUT,
    country,
    segment,
    checkoutId: checkout?.id,
  });
  const handleOnSubmit = useCallback(() => {
    trackPressContinue();
    navigate(`${basePath}/${Routes.CHECKOUT}/${Routes.CHECKOUT_PAYMENT}`, { replace: true });
  }, [basePath, navigate, trackPressContinue]);

  const checkoutItemsKept = useMemo(
    () =>
      checkout?.items.filter(
        (checkoutItem) =>
          checkoutItem.status === CheckoutItemStatus.KEPT || checkoutItem.status === CheckoutItemStatus.REPLACED,
      ),
    [checkout?.items],
  );

  const hasReplacedCheckoutItem = useMemo(
    () => checkout?.items.some((checkoutItem) => checkoutItem.status === CheckoutItemStatus.REPLACED),
    [checkout?.items],
  );

  const trackPressBack = useTrackPressBack({
    page: TrackingPage.CHECKOUT,
    country,
    segment,
    checkoutId: checkout?.id,
  });
  const handleOnBack = useCallback(() => {
    trackPressBack();
    navigate(`${basePath}/${Routes.SUMMARY}`);
  }, [basePath, navigate, trackPressBack]);

  const dependenciesLoadedStatuses = [QueryStatus.ERROR, QueryStatus.SUCCESS];
  const dependenciesLoaded =
    (dependenciesLoadedStatuses.includes(checkoutStatus) || checkout) &&
    dependenciesLoadedStatuses.includes(pricingStatus);

  if (!dependenciesLoaded) {
    return <Spinner />;
  }

  return (
    <Layout
      header={<CheckoutHeader onBack={handleOnBack} />}
      scrollEnabled={false}
      style={{
        header: style.header,
        scrollView: style.scrollView,
      }}
    >
      <ScrollView showsVerticalScrollIndicator={false} testID="checkout-view">
        {hasReplacedCheckoutItem && <DeliveryBanner />}

        <AuroraLayout
          fullWidth={!screen.L}
          style={[screen.L && style.desktopLayoutSpacing, !screen.L && { paddingBottom: pricingHeight }]}
        >
          <Box size={{ L: "2/3" }} style={screen.L && style.desktopListSpacing}>
            <View style={[style.contentWrapper, screen.L && style.desktopContentWrapper]}>
              <Text level={3} style={style.title} heading>
                {titleText}
              </Text>

              {checkoutItemsKept?.map((checkoutItem) => (
                <View key={checkoutItem.id} testID="checkout-items-kept">
                  <ProductVariant
                    brand={checkoutItem.productVariant.brand}
                    color={checkoutItem.productVariant.color}
                    country={country}
                    media={checkoutItem.productVariant.media}
                    name={checkoutItem.productVariant.name}
                    price={checkoutItem.price}
                    status={checkoutItem.status}
                    size={
                      checkoutItem.status === CheckoutItemStatus.REPLACED && checkoutItem.replacedFor
                        ? checkoutItem.replacedFor.size
                        : checkoutItem.productVariant.size
                    }
                  />
                </View>
              ))}

              <View style={style.paymentSelector}>
                <PaymentInstrument useRedirect={useRedirect} />
              </View>
            </View>
          </Box>

          <Box size={{ L: "1/3" }} style={[style.resume, screen.L && style.desktopResume]}>
            {pricing ? (
              <View style={[style.princingWrapper, !screen.L && style.princingWrapperSmall]}>
                <Pricing pricing={pricing} totalCheckoutItemsKept={checkoutItemsKept?.length || 0} />

                {screen.L ? (
                  <Button testID="confirm-checkout-button" onPress={handleOnSubmit}>
                    {submitButtonText}
                  </Button>
                ) : null}
              </View>
            ) : null}
          </Box>
        </AuroraLayout>
      </ScrollView>

      {pricing && !screen.L ? (
        <Sticky style={style.sticky} onLayout={Platform.OS !== "web" ? handleOnPricingLayout : undefined}>
          <Body>
            <Button testID="confirm-checkout-button" small onPress={handleOnSubmit}>
              {submitButtonText}
            </Button>
          </Body>
        </Sticky>
      ) : null}

      {children}
    </Layout>
  );
};

export { Checkout };
