import invariant from "tiny-invariant";
import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { CheckoutItem } from "../../../domain/checkoutItem/model/checkoutItem";
import { CheckoutItemsGetFunction, CheckoutItemsSaveFunction } from "../../../domain/checkoutItem/model/checkoutItems";
import {
  CheckoutItemProductVariantProjection,
  CheckoutItemProjection,
} from "../../../projection/checkoutItem/checkoutItem";
import {
  viewCheckoutItemById,
  ViewCheckoutItemById,
  ViewCheckoutItemByIdResult,
} from "../../../projection/checkoutItem/viewCheckoutItemById";
import { CheckoutDataSource } from "./checkoutDataSource";

const toCheckoutItemProjection: (checkoutItem: CheckoutItem) => CheckoutItemProjection = (checkoutItem) => ({
  id: checkoutItem.aggregateId,
  status: checkoutItem.status,
  productVariant: {} as CheckoutItemProductVariantProjection,
  feedbacks: {},
  replacedFor: null,
  price: checkoutItem.price,
});

const toCheckoutItemDomain: (checkoutItem: ViewCheckoutItemByIdResult) => CheckoutItem | never = (checkoutItem) => {
  invariant(checkoutItem, "No checkoutItem found!");

  return {
    aggregateId: checkoutItem.id,
    id: checkoutItem.id,
    status: checkoutItem.status,
    price: checkoutItem.price,
    feedbacks: checkoutItem.feedbacks,
    replacedForId: checkoutItem.replacedFor?.id,
    domainEvents: [],
  };
};

const getCheckoutItem: CheckoutItemsGetFunction<RepositoryGetFunctionArgs> =
  ({ queryBus }) =>
  async (aggregateId) =>
    toCheckoutItemDomain(
      await queryBus<ViewCheckoutItemById, ViewCheckoutItemByIdResult>(
        viewCheckoutItemById({ checkoutItemId: aggregateId }),
      ),
    );

interface DataSourceCheckoutItemsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly dataSource: CheckoutDataSource;
}

interface DataSourceCheckoutItemsSaveFunction
  extends CheckoutItemsSaveFunction<DataSourceCheckoutItemsSaveFunctionArgs> {}

const saveCheckoutItem: DataSourceCheckoutItemsSaveFunction =
  ({ dataSource }) =>
  async (aggregateRoot) => {
    dataSource.saveCheckoutItem(toCheckoutItemProjection(aggregateRoot));
  };

export { getCheckoutItem, saveCheckoutItem, toCheckoutItemProjection };
