import { CheckoutStatus } from "../../../domain/checkout/model/checkout";
import { CheckoutProjection } from "../../../projection/checkout/checkout";
import { checkoutItem, CheckoutItemFunctionArgs } from "../checkoutItem/checkoutItem.mock";
import { CheckoutDto, toCheckoutProjection } from "./checkout";

const itemIds = [
  "a5422445-0e62-4b11-8a3f-1bb5469ee154",
  "cfc76463-621a-485a-b152-c5a9c4dc43d9",
  "a9a4fa26-fcc2-4efa-a6a8-fa29ea07b128",
  "8af02b73-0b0a-46a0-a3a8-d9710815c739",
  "8890e00b-d4ed-4220-ae13-52cd88ae8ed5",
];
interface CheckoutDtoFunctionArgs {
  readonly status?: CheckoutStatus;
  readonly items: CheckoutItemFunctionArgs[];
}

interface CheckoutDtoFunction {
  (args: CheckoutDtoFunctionArgs): CheckoutDto;
}

const checkoutDto: CheckoutDtoFunction = ({ status = CheckoutStatus.STARTED, items }) => ({
  id: "9c450400-0cd7-44a4-b0e3-e0002a9bf8df",
  status,
  customerId: "0df61ca7-7e4d-462b-a422-a57de0d116b4",
  boxId: "9c406e57-100a-4aa6-83c5-016e7c2970e7",
  checkoutBookingId: "07c996bb-a0b4-45f9-ae21-6bb9c784d12b",
  expiresOn: "2022-09-20",
  items: items.map(({ id, status, feedbacks, replacedFor }, index) =>
    checkoutItem({ id: id || (itemIds[index] as string), status, feedbacks, replacedFor }),
  ),
});

interface CheckoutFunctionArgs extends CheckoutDtoFunctionArgs {}

interface CheckoutFunction {
  (args: CheckoutFunctionArgs): CheckoutProjection;
}

const checkout: CheckoutFunction = ({ status, items }) => toCheckoutProjection(checkoutDto({ status, items }));

export { checkout, checkoutDto };
