/* eslint-disable react/prop-types */
import React from "react";
import { ReturnQuestionType } from "../../../../../../../projection/returnQuestion/returnQuestion.constants";
import { ReturnQuestionItem } from "../ReturnQuestionItem";

const HostDefaultReturnQuestionFeedbackItem: ReturnQuestionItem<ReturnQuestionType.HOST_DEFAULT> = ({ children }) => (
  <>{children}</>
);

export { HostDefaultReturnQuestionFeedbackItem };
