/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from "react";
import { View } from "@lookiero/aurora";
import { useIntl } from "@lookiero/i18n-react";
import { ReturnQuestionType } from "../../../../../../../projection/returnQuestion/returnQuestion.constants";
import { InputField } from "../../../../../../../shared/ui/components/molecules/inputField/InputField";
import { useReturnQuestionFeedbackForReturnQuestion } from "../../behaviors/useReturnQuestionFeedback";
import { ReturnQuestionItem } from "../ReturnQuestionItem";
import { style } from "./TextareaReturnQuestionItem.style";

const TextareaReturnQuestionItem: ReturnQuestionItem<ReturnQuestionType.TEXTAREA> = ({
  returnQuestion,
  returnQuestionParent,
  testID,
}) => {
  const { formatMessage } = useIntl();

  const placeholderText = useMemo(
    () => (returnQuestion.metadata.placeholder ? formatMessage({ id: returnQuestion.metadata.placeholder }) : ""),
    [formatMessage, returnQuestion.metadata.placeholder],
  );

  const { feedback, onChange } = useReturnQuestionFeedbackForReturnQuestion({ returnQuestion: returnQuestionParent });
  const handleOnChange = useCallback(
    (value: string) => onChange({ returnQuestionId: returnQuestionParent.id, returnQuestionFeedback: value }),
    [onChange, returnQuestionParent.id],
  );

  return (
    <View style={style.wrapper}>
      <InputField
        label={placeholderText}
        placeholder={placeholderText}
        testID={testID}
        value={feedback}
        multiline
        onChange={handleOnChange}
      />
    </View>
  );
};

export { TextareaReturnQuestionItem };
