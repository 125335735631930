import React, { FC, useRef } from "react";
import { PaymentInstrumentSelect, Section } from "@lookiero/payments-front";
import { useLogger } from "@lookiero/sty-psp-logging";
import { usePaymentInstrumentEvents } from "../../../../hooks/usePaymentInstrumentEvents";

interface PaymentInstrumentProps {
  readonly useRedirect: () => Record<string, string>;
}
const PaymentInstrument: FC<PaymentInstrumentProps> = ({ useRedirect }) => {
  const paymentInstrumentSelectRef = useRef(null);
  const { returnUrl } = useRedirect();
  const logger = useLogger();

  usePaymentInstrumentEvents({ logger });

  return (
    <PaymentInstrumentSelect
      ref={paymentInstrumentSelectRef}
      beforeRedirect={returnUrl ? () => Promise.resolve(returnUrl) : undefined}
      hasError={false}
      section={Section.BOX_CHECKOUT}
    />
  );
};

export { PaymentInstrument };
