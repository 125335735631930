import { CheckoutProjection } from "../../../projection/checkout/checkout";
import { CheckoutItemProjection } from "../../../projection/checkoutItem/checkoutItem";

interface CheckoutDataSourceFunctionArgs {
  readonly data: CheckoutProjection;
}

interface CheckoutDataSource {
  readonly getCheckout: () => CheckoutProjection;
  readonly saveCheckout: (checkout: CheckoutProjection) => void;
  readonly getCheckoutItem: (id: string) => CheckoutItemProjection | null;
  readonly saveCheckoutItem: (checkoutItem: CheckoutItemProjection) => void;
}

interface CheckoutDataSourceFunction {
  (args: CheckoutDataSourceFunctionArgs): CheckoutDataSource;
}

const checkoutDataSource: CheckoutDataSourceFunction = ({ data: initialData }) => {
  let data: CheckoutProjection = initialData;

  const getCheckout = () => data;
  const saveCheckout = (checkout: CheckoutProjection) => {
    data = checkout;
  };
  const getCheckoutItem = (id: string) => data.items.find((item) => item.id === id) || null;
  const saveCheckoutItem = (checkoutItem: CheckoutItemProjection) => {
    data = {
      ...data,
      items: data.items.map((item) =>
        item.id === checkoutItem.id ? { ...checkoutItem, productVariant: item.productVariant } : item,
      ),
    };
  };

  return {
    getCheckout,
    saveCheckout,
    getCheckoutItem,
    saveCheckoutItem,
  };
};

export type { CheckoutDataSource };
export { checkoutDataSource };
