/* eslint-disable @typescript-eslint/naming-convention */
import {
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithChildren,
  RefAttributes,
  useImperativeHandle,
} from "react";

const setPaymentsBridge = () => void 0;
const PaymentsQueryProvider: FC<PropsWithChildren> = ({ children }) => children;
const PaymentInstrumentSelect: FC = () => null;
interface StartLegacyBoxCheckoutCallbackArgs {
  readonly status: string;
  readonly final: boolean;
}
interface StartLegacyBoxCheckoutFunction {
  (paymentFlowPayload: unknown, callback: (params: StartLegacyBoxCheckoutCallbackArgs) => Promise<void>): void;
}
interface PaymentFlowRef {
  readonly startLegacyBoxCheckout: StartLegacyBoxCheckoutFunction;
}
const paymentFlowRef: PaymentFlowRef = {
  startLegacyBoxCheckout: async (_paymentFlowPayload, callback) => {
    await callback({ status: "EXECUTED", final: true });
  },
};
const PaymentFlow: ForwardRefExoticComponent<RefAttributes<PaymentFlowRef>> = forwardRef<PaymentFlowRef, unknown>(
  (_props, ref) => {
    useImperativeHandle(ref, () => paymentFlowRef, []);
    return null;
  },
);
PaymentFlow.displayName = "PaymentFlow";
enum Section {
  BOX_CHECKOUT = "box-checkout",
}
export type { PaymentFlowRef };
export { PaymentsQueryProvider, PaymentInstrumentSelect, PaymentFlow, Section, setPaymentsBridge, paymentFlowRef };
