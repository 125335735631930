enum ReturnQuestionType {
  HOST_DEFAULT = "HOST_DEFAULT",
  HOST_TEXTAREA = "HOST_TEXTAREA",
  HOST_SELECT = "HOST_SELECT",
  HOST_STACK = "HOST_STACK",
  TEXTAREA = "TEXTAREA",
  OPTION = "OPTION",
}

export { ReturnQuestionType };
