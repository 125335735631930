/* eslint-disable react/prop-types */
import React from "react";
import { ReturnQuestionType } from "../../../../../../../../projection/returnQuestion/returnQuestion.constants";
import ReturnQuestion from "../../../ReturnQuestion";
import { ReturnQuestionItem } from "../../ReturnQuestionItem";

const HostStackReturnQuestionItem: ReturnQuestionItem<ReturnQuestionType.HOST_STACK> = ({
  returnQuestion,
  portalHostName,
}) => (
  <>
    {returnQuestion.children?.map((childReturnQuestion) => (
      <ReturnQuestion
        key={childReturnQuestion.id}
        portalHostName={portalHostName}
        returnQuestion={childReturnQuestion}
        returnQuestionParent={returnQuestion}
      />
    ))}
  </>
);

export { HostStackReturnQuestionItem };
