import { isUuid } from "@lookiero/sty-psp-uuid";
import { FeedbackProjection } from "../../../../../../projection/feedback/feedback";
import { ReturnQuestionProjection } from "../../../../../../projection/returnQuestion/returnQuestion";
import {
  returnQuestionHasChildren,
  returnQuestionWithTranslationKey,
} from "../../../../../../projection/returnQuestion/returnQuestion.typeguard";

interface RecursiveFeedbackForReturnQuestionFunctionArgs {
  readonly feedback: FeedbackProjection;
  readonly returnQuestion: ReturnQuestionProjection;
  readonly translate: (i18nString: string) => string;
  readonly acc?: string[];
}

interface RecursiveFeedbackForReturnQuestionFunction {
  (args: RecursiveFeedbackForReturnQuestionFunctionArgs): string[];
}

const feedbackForReturnQuestion: RecursiveFeedbackForReturnQuestionFunction = ({
  feedback,
  returnQuestion,
  translate,
  acc = [],
}) => {
  const returnQuestionId = Object.keys(feedback).find((id) => id === returnQuestion.id);

  if (returnQuestionId) {
    const returnQuestionFeedback = feedback[returnQuestionId];

    if (isUuid(returnQuestionFeedback as string)) {
      const feebackReturnQuestionChild =
        returnQuestionHasChildren(returnQuestion) &&
        returnQuestion.children?.find((childReturnQuestion) => childReturnQuestion.id === returnQuestionFeedback);

      if (feebackReturnQuestionChild) {
        return [
          ...acc,
          ...(returnQuestionWithTranslationKey(feebackReturnQuestionChild)
            ? [translate(feebackReturnQuestionChild.translationKey)]
            : []),
          ...feedbackForReturnQuestion({ feedback, returnQuestion: feebackReturnQuestionChild, translate, acc }),
        ];
      }

      return acc;
    }

    return [...acc, returnQuestionFeedback as string];
  }

  return (
    (returnQuestionHasChildren(returnQuestion) &&
      returnQuestion.children?.reduce(
        (acc, childReturnQuestion) => [
          ...acc,
          ...feedbackForReturnQuestion({ feedback, returnQuestion: childReturnQuestion, translate, acc }),
        ],
        [] as string[],
      )) ||
    []
  );
};

interface RecursiveDeepestReturnQuestionWithFeedbackFunctionArgs {
  readonly feedback: FeedbackProjection;
  readonly returnQuestion: ReturnQuestionProjection;
  readonly deepestReturnQuestion?: ReturnQuestionProjection;
}

interface RecursiveDeepestReturnQuestionWithFeedbackFunction {
  (
    args: RecursiveDeepestReturnQuestionWithFeedbackFunctionArgs,
  ): [question: ReturnQuestionProjection | undefined, isLeaf: boolean];
}

const deepestReturnQuestionWithFeedbackForReturnQuestion: RecursiveDeepestReturnQuestionWithFeedbackFunction = ({
  feedback,
  returnQuestion,
  deepestReturnQuestion,
}) => {
  const returnQuestionId = Object.keys(feedback).find((id) => id === returnQuestion.id);

  if (returnQuestionId) {
    const returnQuestionFeedback = feedback[returnQuestionId];

    if (isUuid(returnQuestionFeedback as string)) {
      const feebackReturnQuestionChild =
        returnQuestionHasChildren(returnQuestion) &&
        returnQuestion.children?.find((childReturnQuestion) => childReturnQuestion.id === returnQuestionFeedback);

      if (feebackReturnQuestionChild) {
        return deepestReturnQuestionWithFeedbackForReturnQuestion({
          feedback,
          returnQuestion: feebackReturnQuestionChild,
          deepestReturnQuestion: returnQuestion,
        });
      }

      return [returnQuestion, false];
    }

    return [returnQuestion, false];
  }

  const feebackReturnQuestionChild =
    returnQuestionHasChildren(returnQuestion) &&
    returnQuestion.children?.find((childReturnQuestion) =>
      Object.keys(feedback).find((id) => id === childReturnQuestion.id),
    );

  if (feebackReturnQuestionChild) {
    return deepestReturnQuestionWithFeedbackForReturnQuestion({
      feedback,
      returnQuestion: feebackReturnQuestionChild,
      deepestReturnQuestion: feebackReturnQuestionChild,
    });
  }

  const hasChildren = Boolean(
    returnQuestionHasChildren(returnQuestion) && returnQuestion.children && returnQuestion.children.length > 0,
  );

  return [deepestReturnQuestion, !hasChildren];
};

export { feedbackForReturnQuestion, deepestReturnQuestionWithFeedbackForReturnQuestion };
