import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { CheckoutFeedback } from "../../../domain/checkoutFeedback/model/checkoutFeedback";
import {
  CheckoutFeedbacksGetFunction,
  CheckoutFeedbacksSaveFunction,
} from "../../../domain/checkoutFeedback/model/checkoutFeedbacks";
import { CheckoutFeedbackProjection } from "../../../projection/checkoutFeedback/checkoutFeedback";
import { CheckoutFeedbackDataSource } from "./checkoutFeedbackDataSource";

const toCheckoutFeedbackProjection: (checkoutFeedback: CheckoutFeedback) => CheckoutFeedbackProjection = (
  checkoutFeedback,
) => checkoutFeedback.feedbacks;

const getCheckoutFeedback: CheckoutFeedbacksGetFunction<RepositoryGetFunctionArgs> = () => () => {
  throw new Error("There is no equivalent AggregateRoot in the backend");
};

interface DataSourceCheckoutItemsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly dataSource: CheckoutFeedbackDataSource;
}

interface DataSourceCheckoutItemsSaveFunction
  extends CheckoutFeedbacksSaveFunction<DataSourceCheckoutItemsSaveFunctionArgs> {}

const saveCheckoutFeedback: DataSourceCheckoutItemsSaveFunction =
  ({ dataSource }) =>
  async (aggregateRoot) => {
    dataSource.saveCheckoutFeedback(toCheckoutFeedbackProjection(aggregateRoot));
  };

export { getCheckoutFeedback, saveCheckoutFeedback, toCheckoutFeedbackProjection };
