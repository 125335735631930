import { ReturnQuestionProjection } from "../../../../../../projection/returnQuestion/returnQuestion";
import { returnQuestionHasChildren } from "../../../../../../projection/returnQuestion/returnQuestion.typeguard";

interface IsChildReturnQuestionFunctionArgs {
  readonly returnQuestionId: string;
  readonly returnQuestion: ReturnQuestionProjection;
}
interface IsChildReturnQuestionFunction {
  (args: IsChildReturnQuestionFunctionArgs): boolean;
}

const isChildReturnQuestion: IsChildReturnQuestionFunction = ({ returnQuestionId, returnQuestion }) => {
  if (returnQuestionId === returnQuestion.id) {
    return true;
  }

  const children = (returnQuestionHasChildren(returnQuestion) && returnQuestion.children) || [];

  for (let index = 0; index < children.length; index++) {
    const isChild = isChildReturnQuestion({
      returnQuestionId,
      returnQuestion: children[index] as ReturnQuestionProjection,
    });

    if (isChild) {
      return true;
    }
  }

  return false;
};

export { isChildReturnQuestion };
