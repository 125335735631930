/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useI18nMessage, useIntl } from "@lookiero/i18n-react";
import { ReturnQuestionType } from "../../../../../../../projection/returnQuestion/returnQuestion.constants";
import { I18nMessages } from "../../../../../i18n/i18n";
import { useReturnQuestionFeedback } from "../../behaviors/useReturnQuestionFeedback";
import { feedbackForReturnQuestion } from "../../util/returnQuestionFeedback";
import { ReturnQuestionItem } from "../ReturnQuestionItem";
import { style } from "./ReturnQuestionFeedbackItem.style";

const ReturnQuestionFeedbackItem: ReturnQuestionItem<ReturnQuestionType.HOST_DEFAULT> = ({ returnQuestion }) => {
  const titleText = useI18nMessage({ id: returnQuestion.translationKey });
  const unansweredText = useI18nMessage({ id: I18nMessages.FEEDBACK_UNANSWERED });

  const returnQuestionFeedback = useReturnQuestionFeedback();

  const intl = useIntl();
  const translate = useCallback(
    (returnQuestionName: string) => intl.formatMessage({ id: returnQuestionName, defaultMessage: returnQuestionName }),
    [intl],
  );
  const feedback = feedbackForReturnQuestion({ feedback: returnQuestionFeedback, returnQuestion, translate }).join(
    " / ",
  );

  return (
    <View style={style.container}>
      <Text level={2} style={style.title} detailBold>
        {titleText}
      </Text>
      <Text level={1} detail>
        {feedback || unansweredText}
      </Text>
    </View>
  );
};

export { ReturnQuestionFeedbackItem };
