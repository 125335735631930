import React, { FC, useCallback, useMemo } from "react";
import { View } from "react-native";
import { Button } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { CheckoutFeedbackProjection } from "../../../../../../projection/checkoutFeedback/checkoutFeedback";
import {
  CheckoutQuestionProjection,
  CheckoutQuestionType,
} from "../../../../../../projection/checkoutQuestion/checkoutQuestion";
import { CheckoutQuestions } from "../../../../components/organisms/checkoutQuestions/CheckoutQuestions";
import { useCheckoutQuestionFeedback } from "../../../../components/organisms/checkoutQuestions/behaviors/useCheckoutQuestionFeedback";
import {
  CheckoutQuestionItemProvider,
  CheckoutQuestionItems,
} from "../../../../components/organisms/checkoutQuestions/behaviors/useCheckoutQuestionItem";
import { ButtonCheckoutQuestionItem } from "../../../../components/organisms/checkoutQuestions/components/buttonCheckoutQuestionItem/ButtonCheckoutQuestionItem";
import { HostDefaultCheckoutQuestionItem } from "../../../../components/organisms/checkoutQuestions/components/hostDefaultCheckoutQuestionItem/HostDefaultCheckoutQuestionItem";
import { HostSelectCheckoutQuestionItem } from "../../../../components/organisms/checkoutQuestions/components/hostSelectCheckoutQuestionItem/HostSelectCheckoutQuestionItem";
import { IconCheckoutQuestionItem } from "../../../../components/organisms/checkoutQuestions/components/iconCheckoutQuestionItem/IconCheckoutQuestionItem";
import { TextareaCheckoutQuestionItem } from "../../../../components/organisms/checkoutQuestions/components/textareaCheckoutQuestionItem/TextareaCheckoutQuestionItem";
import { I18nMessages } from "../../../../i18n/i18n";

const checkoutQuestionItems: CheckoutQuestionItems = {
  [CheckoutQuestionType.HOST_DEFAULT]: HostDefaultCheckoutQuestionItem,
  [CheckoutQuestionType.HOST_TEXTAREA]: HostDefaultCheckoutQuestionItem,
  [CheckoutQuestionType.HOST_SELECT]: HostSelectCheckoutQuestionItem,
  [CheckoutQuestionType.TEXTAREA]: TextareaCheckoutQuestionItem,
  [CheckoutQuestionType.ICON]: IconCheckoutQuestionItem,
  [CheckoutQuestionType.BUTTON]: ButtonCheckoutQuestionItem,
};

interface CheckoutQuestionsFormProps {
  readonly checkoutQuestions: CheckoutQuestionProjection[];
  readonly submitButtonDisabled: boolean;
  readonly onSubmit: (feedback: CheckoutFeedbackProjection) => void;
}
const CheckoutQuestionsForm: FC<CheckoutQuestionsFormProps> = ({
  checkoutQuestions,
  submitButtonDisabled,
  onSubmit,
}) => {
  const buttonText = useI18nMessage({ id: I18nMessages.FEEDBACK_BUTTON });

  const feedback = useCheckoutQuestionFeedback();
  const handlePress = useCallback(() => onSubmit(feedback), [feedback, onSubmit]);

  const filteredCheckoutQuestions = useMemo(() => {
    const responses = Object.values(feedback);

    return checkoutQuestions.filter(
      (checkoutQuestion) =>
        checkoutQuestion.showCondition.length === 0 ||
        checkoutQuestion.showCondition.some((condition) => responses.includes(condition)),
    );
  }, [checkoutQuestions, feedback]);

  return (
    <View testID="checkout-questions-form">
      <CheckoutQuestionItemProvider checkoutQuestionItems={checkoutQuestionItems}>
        <CheckoutQuestions checkoutQuestions={filteredCheckoutQuestions} />
      </CheckoutQuestionItemProvider>

      <Button busy={submitButtonDisabled} testID="checkout-feedback-button" onPress={handlePress}>
        {buttonText}
      </Button>
    </View>
  );
};

export { CheckoutQuestionsForm };
