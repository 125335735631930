import { PortalHost } from "@gorhom/portal";
import React, { FC, useCallback, useMemo } from "react";
import { Platform } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { generatePath, useNavigate } from "react-router-native";
import { Box, Button, Layout as AuroraLayout, Spinner, Text, View, useDevice } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { CommandStatus } from "@lookiero/messaging-react";
import { Country } from "@lookiero/sty-psp-locale";
import { useLogger } from "@lookiero/sty-psp-logging";
import { Segment } from "@lookiero/sty-psp-segment";
import { Layout as UiLayout } from "@lookiero/sty-psp-ui";
import { CheckoutProjection } from "../../../../../../projection/checkout/checkout";
import { CheckoutItemProjection } from "../../../../../../projection/checkoutItem/checkoutItem";
import { ReturnQuestionType } from "../../../../../../projection/returnQuestion/returnQuestion.constants";
import { useReturnCheckoutItem } from "../../../../../domain/checkoutItem/react/useReturnCheckoutItem";
import { useListReturnQuestionsByCheckoutItemId } from "../../../../../projection/returnQuestion/react/useListReturnQuestionsByCheckoutItemId";
import { TrackingPage } from "../../../../../tracking/tracking";
import { useTrackPageView } from "../../../../../tracking/useTrackPageView";
import { useTrackPressBack } from "../../../../../tracking/useTrackPressBack";
import { useTrackPressNext } from "../../../../../tracking/useTrackPressNext";
import { useTrackReturnItem } from "../../../../../tracking/useTrackReturnItem";
import { ReturnQuestions } from "../../../../components/organisms/returnQuestions/ReturnQuestions";
import { useReturnQuestionFeedback } from "../../../../components/organisms/returnQuestions/behaviors/useReturnQuestionFeedback";
import {
  ReturnQuestionItemProvider,
  ReturnQuestionItems,
} from "../../../../components/organisms/returnQuestions/behaviors/useReturnQuestionItem";
import { HostDefaultReturnQuestionItem } from "../../../../components/organisms/returnQuestions/components/hostDefaultReturnQuestionItem/HostDefaultReturnQuestionItem";
import { HostRadioGroupReturnQuestionItem } from "../../../../components/organisms/returnQuestions/components/hostRadioGroupReturnQuestionItem/HostRadioGroupReturnQuestionItem";
import { HostStackReturnQuestionItem } from "../../../../components/organisms/returnQuestions/components/hostStackReturnQuestionItem/form/HostStackReturnQuestionItem";
import { RadioReturnQuestionItem } from "../../../../components/organisms/returnQuestions/components/radioReturnQuestionItem/RadioReturnQuestionItem";
import { TextareaReturnQuestionItem } from "../../../../components/organisms/returnQuestions/components/textareaReturnQuestionItem/TextareaReturnQuestionItem";
import { ItemDetailHeader } from "../../../../components/templates/header/itemDetailHeader/ItemDetailHeader";
import { I18nMessages } from "../../../../i18n/i18n";
import { Routes } from "../../../../routing/routes";
import { useBasePath } from "../../../../routing/useBasePath";
import { ProductVariantPreview } from "../productVariantPreview/ProductVariantPreview";
import { style } from "./ReturnQuestionsForm.style";

const RETURN_QUESTION_FORM_PORTAL_HOST_NAME = "return-question-form-portal";

const returnQuestionItems: ReturnQuestionItems = {
  [ReturnQuestionType.HOST_DEFAULT]: HostDefaultReturnQuestionItem,
  [ReturnQuestionType.HOST_TEXTAREA]: HostDefaultReturnQuestionItem,
  [ReturnQuestionType.HOST_SELECT]: HostRadioGroupReturnQuestionItem,
  [ReturnQuestionType.HOST_STACK]: HostStackReturnQuestionItem,
  [ReturnQuestionType.TEXTAREA]: TextareaReturnQuestionItem,
  [ReturnQuestionType.OPTION]: RadioReturnQuestionItem,
};

interface ReturnQuestionsFormProps {
  readonly checkout: CheckoutProjection;
  readonly checkoutItem: CheckoutItemProjection;
  readonly country: Country;
  readonly layout: UiLayout;
  readonly segment: Segment;
}

const ReturnQuestionsForm: FC<ReturnQuestionsFormProps> = ({
  checkout,
  checkoutItem,
  country,
  layout: Layout,
  segment,
}) => {
  const navigate = useNavigate();
  const basePath = useBasePath();
  const logger = useLogger();

  const { screen } = useDevice();

  const titleText = useI18nMessage({ id: I18nMessages.RETURN_QUESTIONS_TITLE });
  const descriptionText = useI18nMessage({ id: I18nMessages.RETURN_QUESTIONS_DESCRIPTION });
  const submitButtonText = useI18nMessage({ id: I18nMessages.RETURN_QUESTIONS_SUBMIT_BUTTON });

  const feedback = useReturnQuestionFeedback();
  const [returnQuestions] = useListReturnQuestionsByCheckoutItemId({
    checkoutItemId: checkoutItem.id,
  });

  const [returnCheckoutItem, returnCheckoutItemStatus] = useReturnCheckoutItem({
    checkoutItemId: checkoutItem.id,
    logger,
  });
  const trackReturnItem = useTrackReturnItem({
    page: TrackingPage.RETURN,
    country,
    segment,
    checkoutId: checkout.id,
    checkoutItemId: checkoutItem.id,
  });

  useTrackPageView({ checkoutId: checkout.id, country, segment, page: TrackingPage.RETURN });

  const trackPressBack = useTrackPressBack({
    page: TrackingPage.ITEM,
    country,
    segment,
    checkoutId: checkout?.id,
  });
  const handleOnBack = useCallback(() => {
    trackPressBack();
    navigate(-1);
  }, [navigate, trackPressBack]);

  const trackPressNext = useTrackPressNext({
    page: TrackingPage.ITEM,
    country,
    segment,
    checkoutId: checkout.id,
  });

  const handleOnSubmit = useCallback(async () => {
    returnCheckoutItem({ feedbacks: feedback });
    trackReturnItem();

    const itemIndex = checkout.items.indexOf(checkoutItem);
    const nextItemIndex = itemIndex === checkout.items.length - 1 ? 0 : itemIndex + 1;
    const nextItem = checkout.items[nextItemIndex] as CheckoutItemProjection;

    trackPressNext({ from: checkoutItem.id, to: nextItem.id });
    navigate(generatePath(`${basePath}/${Routes.ITEM}`, { id: nextItem.id }));
  }, [basePath, checkout.items, checkoutItem, feedback, navigate, returnCheckoutItem, trackPressNext, trackReturnItem]);

  const header = useMemo(
    () => (
      <Box style={style.headerWrapper}>
        <ItemDetailHeader onBack={handleOnBack} />
      </Box>
    ),
    [handleOnBack],
  );

  const dependenciesLoaded = checkoutItem && returnQuestions && returnCheckoutItemStatus !== CommandStatus.LOADING;

  if (!dependenciesLoaded) {
    return <Spinner />;
  }

  return (
    <ReturnQuestionItemProvider returnQuestionItems={returnQuestionItems}>
      <PortalHost name={RETURN_QUESTION_FORM_PORTAL_HOST_NAME} />

      <Layout
        footer={null}
        header={header}
        scrollEnabled={false}
        style={{
          safeAreaView: style.safeAreaView,
          scrollView: style.scrollView,
          header: style.header,
        }}
      >
        <ProductVariantPreview country={country} item={checkoutItem} />

        <KeyboardAwareScrollView
          extraScrollHeight={Platform.OS === "android" ? 10 : 120}
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
          testID="return-questions-form"
        >
          <View style={style.background}>
            <AuroraLayout
              fullWidth={!screen.L}
              style={[style.layout, screen.L ? style.desktopLayoutSpacing : undefined]}
            >
              <Box size={{ L: "2/3" }}>
                <View style={[style.info, !screen.S && style.desktopInfo]}>
                  <Text level={3} heading>
                    {titleText}
                  </Text>
                  <Text level={1} style={style.description} detail>
                    {descriptionText}
                  </Text>
                </View>

                <ReturnQuestions
                  portalHostName={RETURN_QUESTION_FORM_PORTAL_HOST_NAME}
                  returnQuestions={returnQuestions}
                />

                <View style={[style.submit, !screen.S && style.submitDesktop]}>
                  <Button testID="return-questions-button" onPress={handleOnSubmit}>
                    {submitButtonText}
                  </Button>
                </View>
              </Box>
            </AuroraLayout>
          </View>
        </KeyboardAwareScrollView>
      </Layout>
    </ReturnQuestionItemProvider>
  );
};

export { ReturnQuestionsForm };
