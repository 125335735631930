/* eslint-disable react/prop-types */
import React from "react";
import { Text, View } from "@lookiero/aurora";
import { useIntl } from "@lookiero/i18n-react";
import { ReturnQuestionType } from "../../../../../../../projection/returnQuestion/returnQuestion.constants";
import { returnQuestionWithTranslationKey } from "../../../../../../../projection/returnQuestion/returnQuestion.typeguard";
import { I18nMessages } from "../../../../../i18n/i18n";
import { ReturnQuestionItem } from "../ReturnQuestionItem";
import { style } from "./HostDefaultReturnQuestionItem.style";

const HostDefaultReturnQuestionItem: ReturnQuestionItem<ReturnQuestionType.HOST_DEFAULT> = ({
  returnQuestion,
  children,
}) => {
  const { formatMessage } = useIntl();
  const isAllOptions = returnQuestion.translationKey === I18nMessages.RETURN_QUESTION_MAIN_ALL_OPINION;

  return (
    <>
      {returnQuestionWithTranslationKey(returnQuestion) && returnQuestion.translationKey !== " " && !isAllOptions ? (
        <View style={style.title}>
          <Text level={3} action>
            {formatMessage({ id: returnQuestion.translationKey })}
          </Text>
        </View>
      ) : null}
      {children}
    </>
  );
};

export { HostDefaultReturnQuestionItem };
