import { CheckoutItemStatus } from "../../../domain/checkoutItem/model/checkoutItem";
import { Currency } from "../../../domain/checkoutItem/model/currency";
import {
  CheckoutItemProductVariantProjection,
  CheckoutItemProjection,
  MediaPerspective,
} from "../../../projection/checkoutItem/checkoutItem";
import { FeedbackProjection } from "../../../projection/feedback/feedback";

interface CheckoutItemFunctionArgs {
  readonly id?: string;
  readonly status: CheckoutItemStatus;
  readonly feedbacks?: FeedbackProjection;
  readonly replacedFor?: CheckoutItemProductVariantProjection;
}

interface CheckoutItemFunction {
  (args: CheckoutItemFunctionArgs): CheckoutItemProjection;
}

const checkoutItem: CheckoutItemFunction = ({
  id = "7b389d69-f157-4234-8531-c1a1eb17dccb",
  status,
  feedbacks = {},
  replacedFor = null,
}) => ({
  id,
  status,
  price: {
    amount: 3999,
    currency: Currency.EUR,
  },
  feedbacks,
  productVariant: {
    id: "7b389d69-f157-4234-8531-c1a1eb17dccb",
    media: [
      {
        id: "d7ec731b-4123-4394-829b-297b952e73e5",
        url: "https://cdn-catalog-back-prod.envs.lookiero.tech/1a/28/1a28f712-a76c-4172-8a11-f15002981dc0.jpg",
        perspective: MediaPerspective.MAIN,
      },
      {
        id: "8055e209-2fe9-44c5-b715-b09ad3ef741c",
        url: "https://cdn-catalog-back-prod.envs.lookiero.tech/15/ac/15aca112-4e71-4646-8db5-7f9723bc0130.jpg",
        perspective: MediaPerspective.DETAIL,
      },
    ],
    brand: "CKS",
    name: "Sari Tshirt lines",
    size: {
      id: "b26f7190-d505-4d24-aeb9-ad36e5e6ec4b",
      lookiero: "M",
      uk: "M",
      it: "M",
      europe: "M",
      unique: false,
    },
    color: {
      id: "c228d1b0-d06d-4d5c-a7d5-953fd43af0d8",
      name: "black",
      label: "black_C2",
    },
  },
  replacedFor,
});

export type { CheckoutItemFunctionArgs };
export { checkoutItem };
