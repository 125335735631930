import { CheckoutBookingProjection } from "../../../projection/checkoutBooking/checkoutBooking";

interface CheckoutBookingDataSourceFunctionArgs {
  readonly data: CheckoutBookingProjection;
}

interface CheckoutBookingDataSource {
  readonly getCheckoutBooking: (id: string) => CheckoutBookingProjection | undefined;
  readonly saveCheckoutBooking: (checkoutBooking: CheckoutBookingProjection) => void;
}

interface CheckoutBookingDataSourceFunction {
  (args: CheckoutBookingDataSourceFunctionArgs): CheckoutBookingDataSource;
}

const checkoutBookingDataSource: CheckoutBookingDataSourceFunction = ({ data: initialData }) => {
  let data: CheckoutBookingProjection = initialData;

  const getCheckoutBooking = () => data;
  const saveCheckoutBooking = (checkoutBooking: CheckoutBookingProjection) => {
    data = checkoutBooking;
  };

  return {
    getCheckoutBooking,
    saveCheckoutBooking,
  };
};

export type { CheckoutBookingDataSource };
export { checkoutBookingDataSource };
