/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { View, Option, OPTION_VARIANT, SIZE } from "@lookiero/aurora";
import { useIntl } from "@lookiero/i18n-react";
import { ReturnQuestionType } from "../../../../../../../projection/returnQuestion/returnQuestion.constants";
import {
  useReturnQuestionFeedback,
  useReturnQuestionFeedbackForReturnQuestion,
} from "../../behaviors/useReturnQuestionFeedback";
import {
  deepestReturnQuestionWithFeedbackForReturnQuestion,
  feedbackForReturnQuestion,
} from "../../util/returnQuestionFeedback";
import { ReturnQuestionItem } from "../ReturnQuestionItem";
import { ModalOptionReturnQuestionItems } from "../modalOptionReturnQuestionItems/ModalOptionReturnQuestionItems";
import { style } from "./RadioReturnQuestionItem.style";

const RadioReturnQuestionItem: ReturnQuestionItem<ReturnQuestionType.OPTION> = ({
  returnQuestion,
  returnQuestionParent,
  testID,
  portalHostName,
}) => {
  const intl = useIntl();
  const [modalVisible, setModalVisible] = useState(false);
  const {
    feedback: feedbackId,
    onChange,
    clear,
  } = useReturnQuestionFeedbackForReturnQuestion({ returnQuestion: returnQuestionParent });
  const feedback = useReturnQuestionFeedback();
  const translate = useCallback(
    (returnQuestionName: string) => intl.formatMessage({ id: returnQuestionName, defaultMessage: returnQuestionName }),
    [intl],
  );

  const inputValue = feedbackForReturnQuestion({ feedback, returnQuestion, translate }).join(" / ");
  const optionText = intl.formatMessage({ id: returnQuestion.translationKey });

  const hasChildren = Boolean(returnQuestion.children && returnQuestion.children.length > 0);

  const handleOnModalClose = useCallback(() => {
    // Uncomment if we want to clear current selection (and update callback's dependencies)
    // clear();

    setModalVisible(false);
  }, []);

  const handleOnPress = useCallback(() => {
    clear();
    onChange({ returnQuestionId: returnQuestionParent.id, returnQuestionFeedback: returnQuestion.id });

    if (hasChildren) {
      setModalVisible(true);
    }
  }, [clear, hasChildren, onChange, returnQuestion.id, returnQuestionParent.id]);

  /**
   * This logic is for closing the Modal when its children has been answered.
   */
  const [deepestReturnQuestionWithFeedback, isLeaf] = deepestReturnQuestionWithFeedbackForReturnQuestion({
    feedback,
    returnQuestion,
  });
  useEffect(() => {
    if (deepestReturnQuestionWithFeedback && isLeaf) {
      setModalVisible(false);
    }
  }, [deepestReturnQuestionWithFeedback, isLeaf]);
  /**
   * This logic is for closing the Modal when its children has been answered.
   */

  return (
    <>
      <View style={style.wrapper} testID={testID}>
        <Option
          checked={returnQuestion.id === feedbackId}
          flex={SIZE.XS}
          name={returnQuestion.id}
          title={inputValue ? `${optionText} / ${inputValue}` : `${optionText}`}
          value={returnQuestion.id}
          variant={OPTION_VARIANT.BUTTON}
          onChange={handleOnPress}
        />
      </View>
      {hasChildren ? (
        <ModalOptionReturnQuestionItems
          portalHostName={portalHostName}
          returnQuestion={returnQuestion}
          returnQuestionParent={returnQuestion}
          visible={modalVisible}
          onClose={handleOnModalClose}
        />
      ) : null}
    </>
  );
};

export { RadioReturnQuestionItem };
