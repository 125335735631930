import {
  ReturnQuestionProjection,
  ReturnQuestionTypesWithChildren,
  ReturnQuestionTypesWithOptionalTranslationKeys,
} from "./returnQuestion";
import { ReturnQuestionType } from "./returnQuestion.constants";

const returnQuestionWithTranslationKey = (
  question: ReturnQuestionProjection,
): question is ReturnQuestionProjection<Exclude<ReturnQuestionType, ReturnQuestionTypesWithOptionalTranslationKeys>> =>
  Boolean(question.translationKey);

const returnQuestionHasChildren = (
  question: ReturnQuestionProjection,
): question is ReturnQuestionProjection<ReturnQuestionTypesWithChildren> =>
  [
    ReturnQuestionType.HOST_DEFAULT,
    ReturnQuestionType.HOST_SELECT,
    ReturnQuestionType.HOST_STACK,
    ReturnQuestionType.HOST_TEXTAREA,
    ReturnQuestionType.OPTION,
  ].includes(question.type);

export { returnQuestionWithTranslationKey, returnQuestionHasChildren };
