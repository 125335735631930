import { Currency } from "../../../domain/checkoutItem/model/currency";
import { PricingProjection } from "../../../projection/pricing/pricing";

interface PricingFunctionArgs {
  readonly paidWithPromocode: boolean;
}

interface PricingFunction {
  (args?: PricingFunctionArgs): PricingProjection;
}

const pricing: PricingFunction = ({ paidWithPromocode } = { paidWithPromocode: false }) => ({
  orderTotal: {
    amount: 18301,
    currency: Currency.EUR,
  },
  pendingToPay: {
    amount: 16301,
    currency: Currency.EUR,
  },
  subtotal: {
    amount: 21801,
    currency: Currency.EUR,
  },
  discount: {
    amount: -2500,
    currency: Currency.EUR,
  },
  discountPercentage: 25,
  balanceDiscount: {
    amount: -2000,
    currency: Currency.EUR,
  },
  service: {
    reference: "LK-service",
    originalPrice: {
      amount: 10.0,
      currency: Currency.EUR,
    },
    discount: {
      amount: 0,
      currency: Currency.EUR,
    },
    finalPrice: {
      amount: -1000,
      currency: Currency.EUR,
    },
    prepaid: true,
    paidWithPromocode,
  },
});

export { pricing };
