import { BuildBootstrapFunctionReturn } from "@lookiero/messaging-react";
import { CheckoutItemStatus } from "../../domain/checkoutItem/model/checkoutItem";
import { BookedProductsVariantsForCheckoutItemView } from "../../projection/bookedProductsVariants/viewBookedProductVariantsForCheckoutItem";
import { CheckoutByIdView } from "../../projection/checkout/viewCheckoutById";
import { FirstAvailableCheckoutByCustomerIdView } from "../../projection/checkout/viewFirstAvailableCheckoutByCustomerId";
import { FiveItemsDiscountByCustomerIdView } from "../../projection/checkout/viewFiveItemsDiscountByCustomerId";
import { IsCheckoutEnabledByCustomerIdView } from "../../projection/checkout/viewIsCheckoutEnabledByCustomerId";
import { IsSizeChangeEnabledByCheckoutIdView } from "../../projection/checkout/viewIsSizeChangeEnabledByCheckoutId";
import { CheckoutBookingByIdView } from "../../projection/checkoutBooking/viewCheckoutBookingById";
import { CheckoutItemByIdView } from "../../projection/checkoutItem/viewCheckoutItemById";
import { CheckoutQuestionsByCheckoutIdView } from "../../projection/checkoutQuestion/listCheckoutQuestionsByCheckoutId";
import { PaymentFlowPayloadByCheckoutIdView } from "../../projection/payment/viewPaymentFlowPayloadByCheckoutId";
import { PricingByCheckoutIdView } from "../../projection/pricing/viewPricingByCheckoutId";
import { ReturnQuestionsByCheckoutItemIdView } from "../../projection/returnQuestion/listReturnQuestionsByCheckoutItemId";
import { getUiSetting, saveUiSetting } from "../domain/uiSetting/model/storageUiSettings";
import { read, write } from "../persistence/asyncStorageStorage";
import { bookedProductsVariants } from "../projection/bookedProductsVariants/bookedProductsVariants.mock";
import { checkout } from "../projection/checkout/checkout.mock";
import { checkoutBooking as checkoutBookingMock } from "../projection/checkoutBooking/checkoutBooking.mock";
import { checkoutFeedback as checkoutFeedbackMock } from "../projection/checkoutFeedback/checkoutFeedback.mock";
import { checkoutQuestions as checkoutQuestionsMock } from "../projection/checkoutQuestion/checkoutQuestions.mock";
import { feedback as feedbackMock } from "../projection/feedback/feedback.mock";
import { paymentFlowPayload as paymentFlowPayloadMock } from "../projection/payment/paymentFlowPayload.mock";
import { pricing as pricingMock } from "../projection/pricing/pricing.mock";
import { returnQuestions as returnQuestionsMock } from "../projection/returnQuestion/returnQuestions.mock";
import { storageUiSettingByKeyView } from "../projection/uiSetting/storageUiSettingByKeyView";
import { baseBootstrap } from "./baseBootstrap";
import { checkoutBookingDataSource } from "./mock/checkoutBookingDataSource";
import { checkoutDataSource } from "./mock/checkoutDataSource";
import { checkoutFeedbackDataSource } from "./mock/checkoutFeedbackDataSource";
import { getCheckoutBooking, saveCheckoutBooking } from "./mock/dataSourceCheckoutBookings";
import { getCheckoutFeedback, saveCheckoutFeedback } from "./mock/dataSourceCheckoutFeedbacks";
import { getCheckoutItem, saveCheckoutItem } from "./mock/dataSourceCheckoutItems";
import { getCheckout, saveCheckout } from "./mock/dataSourceCheckouts";

const IS_CHECKOUT_ENABLED = true;
const IS_SIZE_CHANGE_ENABLED = true;
const FIVE_ITEMS_DISCOUNT = 25;

const dataSource = checkoutDataSource({
  data: checkout({
    items: [
      {
        status: CheckoutItemStatus.INITIAL,
        feedbacks: feedbackMock,
      },
      {
        status: CheckoutItemStatus.INITIAL,
      },
      {
        status: CheckoutItemStatus.INITIAL,
      },
      {
        status: CheckoutItemStatus.INITIAL,
      },
      {
        status: CheckoutItemStatus.INITIAL,
      },
    ],
  }),
});
const checkoutBookingData = checkoutBookingDataSource({ data: checkoutBookingMock });
const checkoutFeedbackData = checkoutFeedbackDataSource({ data: checkoutFeedbackMock });

const checkoutByIdView: CheckoutByIdView = async () => dataSource.getCheckout();
const firstAvailableCheckoutByCustomerIdView: FirstAvailableCheckoutByCustomerIdView = async () =>
  dataSource.getCheckout();
const isCheckoutEnabledByCustomerIdView: IsCheckoutEnabledByCustomerIdView = async () => IS_CHECKOUT_ENABLED;
const isSizeChangeEnabledByCheckoutIdView: IsSizeChangeEnabledByCheckoutIdView = async () => IS_SIZE_CHANGE_ENABLED;
const fiveItemsDiscountByCustomerIdView: FiveItemsDiscountByCustomerIdView = async () => FIVE_ITEMS_DISCOUNT;
const checkoutItemByIdView: CheckoutItemByIdView = async ({ checkoutItemId }) =>
  dataSource.getCheckoutItem(checkoutItemId);
const returnQuestionsByCheckoutItemIdView: ReturnQuestionsByCheckoutItemIdView = async () => returnQuestionsMock;
const checkoutBookingByIdView: CheckoutBookingByIdView = async () => checkoutBookingMock;
const bookedProductsVariantsForCheckoutItemView: BookedProductsVariantsForCheckoutItemView = async () =>
  bookedProductsVariants;
const pricingByCheckoutIdView: PricingByCheckoutIdView = async () => pricingMock();
const paymentFlowPayloadByCheckoutIdView: PaymentFlowPayloadByCheckoutIdView = async () => paymentFlowPayloadMock;
const checkoutQuestionsByCheckoutIdView: CheckoutQuestionsByCheckoutIdView = async () => checkoutQuestionsMock;

const bootstrap: () => BuildBootstrapFunctionReturn = () =>
  baseBootstrap({
    checkoutByIdView,
    firstAvailableCheckoutByCustomerIdView,
    isCheckoutEnabledByCustomerIdView,
    isSizeChangeEnabledByCheckoutIdView,
    fiveItemsDiscountByCustomerIdView,
    uiSettingByKeyView: storageUiSettingByKeyView({ read }),
    checkoutItemByIdView,
    returnQuestionsByCheckoutItemIdView,
    checkoutBookingByIdView,
    bookedProductsVariantsForCheckoutItemView,
    pricingByCheckoutIdView,
    paymentFlowPayloadByCheckoutIdView,
    checkoutQuestionsByCheckoutIdView,
    getUiSetting,
    saveUiSetting,
    uiSettingsDependencies: [{ read, write }],
    getCheckout,
    saveCheckout,
    checkoutsDependencies: [{ dataSource }],
    getCheckoutItem,
    saveCheckoutItem,
    checkoutItemsDependencies: [{ dataSource }],
    getCheckoutBooking,
    saveCheckoutBooking,
    checkoutBookingsDependencies: [{ dataSource: checkoutBookingData }],
    getCheckoutFeedback,
    saveCheckoutFeedback,
    checkoutFeedbacksDependencies: [{ dataSource: checkoutFeedbackData }],
  });

export { bootstrap };
